import React from 'react';
import Typography from '@material-ui/core/Typography';

import { Layout } from '../containers';
import {
  Container,
  FullPageContainer,
  GradientBackground,
  Paragraph,
} from '../styled';

const NotFoundPage = () => (
  <Layout>
    <GradientBackground angle="310">
      <Container>
        <FullPageContainer>
          <Typography variant="h1">
            404
          </Typography>
          <Typography variant="h3" gutterBottom>
            NOT FOUND
          </Typography>
          <Paragraph size="3" opacity=".6">
            You just hit a route that doesn&#39;t exist... the sadness.
          </Paragraph>
        </FullPageContainer>
      </Container>
    </GradientBackground>
  </Layout>
);

export default NotFoundPage;
